import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../../environments/environment'
import { BossApiListResponse, BossApiResponse } from '../data/models/boss-api-response'
import { SaveTenantUserRequest, TenantUser, UserFilterSettings, UserGroup } from '../models/user.model'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TenantAdminService {
  private httpClient = inject(HttpClient)
  private baseURL = 'tenant-api/tenant-admin'

  getUsers({ selectedPage, pageSize, sortField, sortOrder, creationContext, userIds, text }: UserFilterSettings) {
    return this.httpClient.get<BossApiListResponse<TenantUser>>(`${environment.bossApi}/${this.baseURL}/users`, {
      params: {
        selectedPage: `${selectedPage}`,
        pageSize: `${pageSize}`,
        sortField,
        sortOrder,
        creationContext,
        ...(userIds && { userIds }),
        ...(text && { text })
      }
    })
  }

  getUser(id: string) {
    return this.httpClient.get<BossApiResponse<TenantUser>>(`${environment.bossApi}/${this.baseURL}/user/${id}`)
  }

  updateUser(userId: string, user: SaveTenantUserRequest): Observable<{ userId: string }> {
    return this.httpClient
      .put<void>(`${environment.bossApi}/${this.baseURL}/user/${userId}`, {
        data: user
      })
      .pipe(map(() => ({ userId })))
  }

  createUser(user: SaveTenantUserRequest): Observable<{ userId: string }> {
    return this.httpClient
      .post<BossApiResponse<string>>(`${environment.bossApi}/${this.baseURL}/user`, {
        data: user
      })
      .pipe(map(response => ({ userId: response.data })))
  }

  deleteUser(id: string) {
    return this.httpClient.delete<BossApiResponse<any>>(`${environment.bossApi}/${this.baseURL}/user/${id}`)
  }

  reinviteUser(id: string) {
    return this.httpClient.post<BossApiResponse<TenantUser>>(
      `${environment.bossApi}/${this.baseURL}/user/${id}/invite`,
      {}
    )
  }

  sendPasswordResetEmail(id: string) {
    return this.httpClient.post<BossApiResponse<TenantUser>>(
      `${environment.bossApi}/${this.baseURL}/user/${id}/resetpassword`,
      {}
    )
  }

  uploadUserProfilePicture(userId: string, profilePicture: File) {
    const formData = new FormData()
    formData.append('profilePicture', profilePicture)
    return this.httpClient.post<BossApiResponse<string>>(
      `${environment.bossApi}/${this.baseURL}/user/${userId}/profilepicture`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  }

  getUserGroups() {
    return this.httpClient.get<BossApiListResponse<UserGroup>>(
      `${environment.bossApi}/${this.baseURL}/healthcareservice/usergroups`
    )
  }
}
